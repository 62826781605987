import { MARKETING_API } from 'constants/APIUri';
import { GET } from './Clients';

const getListBanner = (ctx) => {
  const url = MARKETING_API.BANNER;
  return GET({ url, ctx, isBasic: true, isAuth: false, cache: true, cacheServer: false });
};

const getHashtagTopSearch = () => GET({ url: '/web/top-search', mock: true, isAuth: false });

const getProductFiles = ({ q = {}, isMienBac = false }) =>
  GET({ url: '/web/sanphammoi', params: { q: JSON.stringify(q), isMienBac }, mock: true, isAuth: false });

const getListBannerMock = () => {
  const url = '/web/banner';
  return GET({ url, mock: true, isAuth: false });
};

const getMostSearchMock = () => GET({ url: '/web/most-search', mock: true, isAuth: false });

const getSections = ({ q = {}, ctx, ...restProps }) => GET({ ctx, url: MARKETING_API.SECTION, params: { q: JSON.stringify({ ...q }) } });

const getPolicies = (ctx) => GET({ ctx, url: MARKETING_API.POLICY, isBasic: true, isAuth: false, cache: true, cacheServer: false });

export default {
  getListBanner,
  getListBannerMock,
  getMostSearchMock,
  getHashtagTopSearch,
  getProductFiles,
  getSections,
  getPolicies,
};
